<template>
    <div class="chat-body" :class="{ secret: $isPrivacy() }">
        <FloatingComponents :chat="chat" />
        <div
            ref="messages"
            class="messages ver-scroll"
            :class="{
                'more-padding': chat.chat_type === 'users',
                'stop-scrolling': loading,
                vacation: chatType === 'pro_agent' && user.vacation,
            }"
            @scroll="onScroll"
        >
            <div v-if="showAlimiOpenChatMessage" class="message-row left">
                <div class="profile-and-name flex-row">
                    <img class="img-profile flex-wrap" :src="require('@/assets/images/logo_symbol.png')" />
                    <div class="name flex-wrap" v-html="$translate('GOODPERSON')" />
                </div>
                <Message class="flex-wrap" :message="alimiOpenChatMessage" :me="false" />
            </div>
            <div
                class="message-row"
                :class="[shouldAddMoreMargin(idx), isMine(message) ? 'right' : 'left']"
                :key="(message || {}).id"
                v-for="(message, idx) in messages"
            >
                <UnreadSeparator v-if="showUnreadSeparator(message, idx)" />

                <!-- <UserChatInfo v-if="chat.chat_type === ('users') && message.mtype === 'open-chat-introduce'" /> -->
                <UserChatInfo
                    v-if="
                        (chat.chat_type === 'users' || chat.chat_type === 'schedule_change') &&
                        (message.mtype === 'open-chat-introduce' || message.mtype === 'open-chat-schedule-change')
                    "
                    :chatType="chat.chat_type"
                />

                <DailySeparator v-if="showDailySeparator(idx)" :message="message" />
                <template>
                    <div v-if="!isMine(message) && message.mtype !== 'request'" class="profile-and-name flex-row">
                        <img class="img-profile flex-wrap" v-lazy="sender(message).photo_url" />

                        <div class="name flex-wrap">
                            <span
                                v-if="isScheduleChange"
                                v-html="message.user_id === 0 ? '굿퍼슨' : user.nickname || user.name"
                            />
                            <span v-else v-html="message.user_id === 0 ? '알리미' : user.nickname || user.name" />
                            <i @click="deleteMessage(message)" v-if="$isTester()" class="material-icons m-r f-14"
                                >close</i
                            >
                        </div>
                    </div>

                    <Message
                        class="flex-wrap"
                        :message="message"
                        :me="isMine(message)"
                        :profilePhotoMessage="profilePhotoMessage"
                    />
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import DailySeparator from './DailySeparator'
import UnreadSeparator from './UnreadSeparator'
import FloatingComponents from '@/routes/chat/chatroom/components/floating-components/FloatingComponents'
import Message from './message/Message'
import UserChatInfo from '@/routes/chat/chatroom/components/UserChatInfo'
import chatService from '@/services/chat'

export default {
    name: 'ChatBody',
    props: {
        unreadCount: Number,
        chat: Object,
    },
    components: { DailySeparator, UnreadSeparator, Message, UserChatInfo, FloatingComponents },
    data: () => ({
        loading: false,
        firstUnreadMessage: null,
        userSuggestLen: 0,
        allMessages: [],
    }),
    computed: {
        messageNeedFiltered() {
            return ['schedule-finish-noti']
        },
        messages() {
            // if (this.$isTester() || this.device.os === 'ios') {
            if (this.$isTester()) {
                const messageNeedFiltered = ['schedule-finish-noti', 'introduce-product']
                const messages = (this.chat.$$messages || []).filter(m => !messageNeedFiltered.includes(m.mtype))
                return (messages || []).slice().reverse()
            } else {
                const messages = (this.chat.$$messages || []).filter(m => !this.messageNeedFiltered.includes(m.mtype))
                return (messages || []).slice().reverse()
            }
        },
        profilePhotoMessage() {
            return this.allMessages.filter(
                el =>
                    el.mtype === 'profile-photo' &&
                    this.$mustParse(el.content).accept_status === undefined &&
                    (!el.content.includes('show_photo') || this.$mustParse(el.content).show_photo)
            )
        },
        user() {
            return this.chat.user
        },
        // chat() {
        //     return this.$store.getters.chat || {}
        // },
        chatType() {
            return this.chat.chat_type
        },
        instantChat() {
            return this.$isInstantChat(this.chat)
        },
        agentId() {
            return this.chat.agent_id
        },
        isAlimi() {
            return this.chatType === 'alimi'
        },
        isScheduleChange() {
            return this.chatType === 'schedule_change'
        },
        alimiOpenChatMessage() {
            if (!this.chat) return

            return {
                chat_id: this.chat.id,
                content: '안녕하세요, 굿퍼슨입니다!\n앞으로 이곳에서 공지사항과 다양한 소식들을 전달해드릴게요! 🤓',
                user_id: 0,
                mtype: 'text',
                is_template: null,
                direction: null,
            }
        },
        showAlimiOpenChatMessage() {
            if (!this.isAlimi || !this.chat || this.chat.$$loadMore) return false

            return true
        },
        me() {
            return this.$store.getters.me || {}
        },
        isInstantChat() {
            return !!this.chat.expire_at
        },
        title() {
            if (!this.chat || (!this.chat.user && !this.chat.users)) return

            if (this.chat.chat_type === 'alimi') {
                return `알리미`
            } else if (this.isInstantChat) {
                return `${this.chat.user.nickname}
                    <span class="expire-badge">임시</span>`
            }

            return `${this.chat.user.nickname}`
        },
        device() {
            return this.$store.getters.device || {}
        },
    },
    // watch: {
    //     messages() {
    //         this.getAllMessages()
    //     },
    // },
    methods: {
        async deleteMessage(message) {
            const idx = await this.$modal.basic({
                body: '해당 메시지를 삭제합니다',
                buttons: [
                    {
                        label: 'CANCEL',
                        class: 'btn-default',
                    },
                    {
                        label: 'CONFIRM',
                        class: 'btn-primary',
                    },
                ],
            })
            if (idx) {
                if (!this.$isTester()) return

                const res = await chatService.deleteMessage(this.chat.id, message.id)
                if (res) {
                    const messages = this.chat.$$messages
                    const idx = messages.findIndex(m => m.id === message.id)
                    const targetMessage = messages.find(m => m.id === message.id)
                    messages.splice(idx, 1, { ...targetMessage, mtype: 'text', content: '삭제된 메시지입니다.' })
                    this.$set(this.chat, '$$messages', messages)
                    this.$toast.success(res.msg)
                }
            }
        },
        async initScrollPosition() {
            if (this.unreadCount > this.messages.length) {
                const loadMessages = () => {
                    this.chat.$$firstMessageId = this.messages[0].id
                    return this.$store.dispatch('loadMessages', {
                        firstId: this.chat.$$firstMessageId,
                        chatId: this.chat.id,
                    })
                }

                const loopCount = parseInt(this.unreadCount / this.messages.length)

                for (let i = 0; i < loopCount; i += 1) {
                    await loadMessages()
                }
            }

            const dom = this.$refs.messages
            const unreadElem = document.querySelector('.unread-separator')

            if (unreadElem) {
                await this.$waitFor(200)
                unreadElem.scrollIntoView(true)
                if (dom.scrollTop + dom.clientHeight < dom.scrollHeight) {
                    dom.scrollTop -= dom.clientHeight / 3
                }
            } else {
                await this.$waitFor(200)
                this.$scroll.down(dom, false) // 메시지 랜더링이 채 끝나기 전에 scrolldown 하면 밑으로 제대로 내려가지 못함
            }
        },
        async loadMoreMessages() {
            if (this.chat.$$loadMore === false) {
                this.loading = false
                return
            }

            const dom = this.$refs.messages
            const scrollHeightBefore = dom.scrollHeight // 메시지 추가 전 스크롤 높이 기억

            try {
                await this.$store.dispatch('loadMessages', {
                    firstId: this.chat.$$firstMessageId,
                    chatId: this.chat.id,
                })
            } catch (e) {
                this.$toast.error(e.data)
            } finally {
                this.$nextTick(() => {
                    const scrollHeightAfter = dom.scrollHeight // 메시지 추가 후 스크롤 높이
                    setTimeout(() => {
                        dom.scrollTop = scrollHeightAfter - scrollHeightBefore
                    }, 0) // 이상해보이지만 ios 에서는 큐에 넣어두어야 올바르게 실행됨
                    setTimeout(() => {
                        this.loading = false
                    }, 100) // 충분한 시간을 두고 false 처리해줘야 여러번 호출 되는 것을 방지할 수 있음
                })
            }
        },
        vb(message) {
            const content = this.$mustParse(message.content) || {}
            if (!content.is_vb) return

            return {
                photo_url: require('@/assets/images/logo_symbol.png'),
                name: '굿퍼슨',
            }
        },
        sender(message) {
            const vb = this.vb(message)
            if (vb) {
                return this.vb(message)
            }

            if (this.chatType === 'alimi' || message.user_id === 0) {
                return {
                    photo_url: require('@/assets/images/logo_symbol.png'),
                    name: '알리미',
                }
            }

            return {
                name: `${this.agentTitle()} <span class="f-medium m-l-2"> ${this.user.name}</span>`,
                photo_url: this.user.photo_url,
            }
        },
        agentTitle() {
            return '주선자'
        },
        isMine(message) {
            const content = this.$mustParse(message.content) || {}
            if (content.is_vb) return false

            return message.user_id === this.me.id
        },
        onScroll(event) {
            if (this.loading) return

            const scrollTop = event.target.scrollTop
            if (scrollTop > 100 || !this.messages || this.messages.length === 0) {
                return
            }

            this.loading = true
            this.chat.$$firstMessageId = this.messages[0].id
            this.loadMoreMessages()
        },
        showDailySeparator(idx) {
            if (idx === 0) {
                return true
            }

            const curMessage = this.messages[idx]
            if (!curMessage || !curMessage.created_at) return false

            const prevMessage = this.messages[idx - 1]
            return (
                this.$moment(prevMessage.created_at).format('YYYY-MM-DD') !==
                this.$moment(curMessage.created_at).format('YYYY-MM-DD')
            )
        },
        showUnreadSeparator(message, idx) {
            if (this.unreadCount === 0 || this.messages.length === 1) return false

            if (
                this.firstUnreadMessage &&
                this.firstUnreadMessage.id === message.id &&
                this.firstUnreadMessage.user_id !== this.me.id &&
                !this.firstUnreadMessage.mtype.includes('open-chat')
            )
                return true

            if (this.messages.length - this.unreadCount === idx) {
                if (!this.firstUnreadMessage) {
                    this.firstUnreadMessage = message
                    return true
                }
            }

            return false
        },
        shouldAddMoreMargin(msgIdx) {
            const curMessage = this.messages[msgIdx]
            const nextMessage = this.messages[msgIdx + 1]
            if (!curMessage || !nextMessage) return

            if (curMessage.user_id !== nextMessage.user_id) {
                return 'm-b-20'
            }

            if (['text', 'photo', 'multi-photo'].indexOf(curMessage.mtype) === -1) {
                return 'm-b-16'
            }
        },
        onChatInputFocused() {
            const dom = this.$refs.messages
            const { scrollHeight, clientHeight, scrollTop } = dom

            if (scrollHeight <= clientHeight + scrollTop) {
                setTimeout(() => {
                    this.$scroll.down(dom, true)
                }, 400)
            }
        },
        // async getAllMessages() {
        //     // console.log("getAllMessages called")
        //     let allMessages = [...this.messages]
        //     let firstMessageId = this.chat.$$firstMessageId
        //     while (true) {
        //         const data = await chatService.messages(this.chat.id, firstMessageId)
        //         allMessages = [...data.messages, ...allMessages]
        //         if (!data.load_more) break
        //         firstMessageId = data.messages[data.messages.length - 1].id
        //     }

        //     this.allMessages = allMessages
        // },
    },
    mounted() {
        if (this.$isPrivacy()) {
            this.$nativeBridge.postMessage({
                action: 'setBackgroundColor',
                value: {
                    top: '#393d44',
                    bottom: '#393d44',
                },
            })
        }

        this.$bus.$on('chatInputFocus', this.onChatInputFocused)
        this.$nextTick(() => {
            this.initScrollPosition()
        })

        // this.getAllMessages()
    },
    beforeDestroy() {
        this.$bus.$off('chatInputFocus', this.onChatInputFocused)
        // this.$nativeBridge.postMessage({
        //     action: 'setBackgroundColor',
        //     value: '#FFFFFF',
        // })
    },
}
</script>

<style lang="scss" scoped>
.chat-body {
    position: relative;
    touch-action: none;

    &.secret {
        background: $grey-03;
    }

    .message-row {
        .profile-and-name {
            .img-profile {
                width: 28px;
                height: 28px;
                background-color: white;
                @include center;

                .material-icons {
                    color: $grey-04;
                    font-size: 20px;
                }
                &.real-friend-profile {
                    border: solid 1px $gray-divider;
                }
            }
            .name {
                margin-left: 8px;
                font-size: 15px;
                color: #181f39;
                @include items-center;
                @include f-regular;
            }
        }
        &:last-child {
            padding-top: 0;
        }
        &.left {
            .message {
                margin-top: 2px;
                margin-left: 36px;
            }
        }
    }
    .messages {
        padding: 16px;
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
        display: flex;
        flex-direction: column;

        &.more-padding {
            padding-top: 60px;
        }

        &.vacation {
            padding-top: 32px !important;
        }
    }
    .stop-scrolling {
        height: 100%;
        overflow: hidden;
    }
}
</style>
